// import i18next from '@theme/translations'

const siteConfig = {
  winery: 'mascot',
  metadata: {
    title: `The Mascot`,
    phone: '707.944.0350',
    wines: 'The Mascot',
    description: `Made from the younger vines of Harlan Estate, BOND, and Promontory, The Mascot shares a unique glimpse into the evolution of our winegrowing estates.`,
  },
  loginPath: '/login',
  loginTitle: 'Login',
  // signupPath: '/allocation',
  // signupTitle: 'Request Allocation'
  countOfInHouseInventoryLocations: 1,
  director: {
    name: 'Mollie Maisch',
    nameWithTitle: 'Mollie Maisch',
    email: 'maisch@mascotwine.com',
  },
  patronRelations: {
    name: 'Christy Argast Juarros',
    email: 'argast@mascotwine.com',
  },
  isFlacActive: false,
  nextVintageWines: '2021 vintage of The Mascot',
  lastVintageWines: '2020 vintage of The Mascot',
  settings: {
    skipLetterDestination: '/allocation',
    useCustomerLetters: false,
  },
}

if (process.env.GATSBY_SENTRY_ENV == 'production') {
  siteConfig.ccWorkaroundInventoryLocationId = 'aadba2f6-cc45-4b01-869f-23a7f6823aa3' // "the-mascot-production", Pick up at The Mascot Office
} else {
  siteConfig.ccWorkaroundInventoryLocationId = '6816db48-035a-4c57-a117-3cd2da949c97' // "the-mascot", Pick up at The Mascot Office
}

export default siteConfig
